import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-card',
  template: `<ng-content />`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    .docs-card {
      border: none;
      display: flex;
      border-width: 0;
      border-radius: 4px;
      position: relative;
      flex-direction: column;
      box-sizing: border-box;
      background-color: white;
      box-shadow: 1px 2px 6px -1px rgba(0, 0, 0, 0.16),
        0px 1px 2px 0px rgba(0, 0, 0, 0.1),
        0px 1px 4px 0px rgba(0, 0, 0, 0.16);
    }
  `,
  host: {
    class: 'docs-card'
  }
})
export class Card {}

@Component({
  standalone: true,
  selector: 'app-card-content',
  template: `<ng-content />`,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    .docs-card-content {
      display: block;
      padding: 0 16px;

      &:first-child {
        padding-top: 16px;
      }

      &:last-child {
        padding-bottom: 16px;
      }

      > :last-child:not(.docs-card-footer) {
        margin-bottom: 0;
      }
    }
  `,
  host: {
    class: 'docs-card-content'
  }
})
export class CardContent {}

@Component({
  standalone: true,
  selector: 'app-card-header',
  template: `
    <ng-content select=".docs-card-avatar" />
    <div class="docs-card-header-text">
      <ng-content select=".docs-card-title" />
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    .docs-card-header {
      display: flex;
      padding: 16px 16px 0;

      .docs-card-title {
        margin: 0;
        padding-top: 0;
        display: block;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.0125em;
      }

      .docs-card-avatar {
        width: 65px;
        height: 65px;
        flex-shrink: 0;
        object-fit: cover;
        border-radius: 50%;
        margin-bottom: 16px;

        & ~ .docs-card-header-text {
          .docs-card-title {
            padding: 16px 16px 0;
          }
        }
      }
    }
  `,
  host: {
    class: 'docs-card-header'
  }
})
export class CardHeader {}

@Component({
  standalone: true,
  template: `<ng-content />`,
  selector: 'app-card-footer',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    .docs-card-footer {
      padding: 16px;
      display: block;
    }
  `,
  host: {
    'class': 'docs-card-footer',
  },
})
export class CardFooter {}
